<template>
  <div class="p-05" @keyup.enter="onSearch">
    <p class="is-size-3 has-text-centered">Search</p>
    <p
      class="has-text-primary has-text-centered has-text-weight-semibold"
      style="margin: 1em 0"
    >
      Enter your PDF search criteria below:
    </p>
    <b-field label="From:">
      <b-datepicker
        v-model="localSearchParams.time_Date_gte"
        placeholder="Click to select..."
        icon="calendar"
        trap-focus
        :first-day-of-week="1"
      />
      <b-button
        icon-left="times"
        @click="localSearchParams.time_Date_gte = null"
      />
    </b-field>
    <b-field label="To:">
      <b-datepicker
        v-model="localSearchParams.time_Date_lte"
        placeholder="Click to select..."
        icon="calendar"
        trap-focus
        :first-day-of-week="1"
      />
      <b-button
        icon-left="times"
        @click="localSearchParams.time_Date_lte = null"
      />
    </b-field>
    <search-component
      v-for="(component, key) in searchFields"
      :key="key"
      :component="component"
      :search-params="localSearchParams"
      @search-param-changed="amendSearchParam"
    />
    <div class="buttons">
      <b-button type="is-info" @click="onSearch">Search</b-button>
      <b-button type="is-dark" @click="clearSearchParams">Clear</b-button>
      <b-button type="is-neutral" @click="$emit('on-close')">Close</b-button>
    </div>
  </div>
</template>
<script>
import SearchComponent from '@/components/ofsc/forms/SearchComponent';
import { mapActions } from 'vuex';
import { set } from 'lodash';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SearchSidebar',
  components: { SearchComponent },
  props: {
    searchFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localSearchParams: {},
    };
  },
  methods: {
    ...mapActions('storeForms', ['setSearchParams']),
    onSearch() {
      this.setSearchParams(JSON.parse(JSON.stringify(this.localSearchParams)));
      this.$emit('on-search');
    },
    amendSearchParam(param) {
      const fieldNamePieces = param.fieldName.split('.');
      set(this.localSearchParams, fieldNamePieces, param.inputData);
    },
    clearSearchParams() {
      this.localSearchParams = {};
    },
  },
});
</script>

<style scoped>
.p-05 {
  margin: 0.5rem;
}
</style>
