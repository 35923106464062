import { get } from 'lodash';
import moment from 'moment';
import { getImageByNameFromApi } from '@/services/forms/searchApi';
import { onePxImage, missingOfscImageWarning } from './images';

const findField = RegExp(/{{FIELD.*?}}/g);
const findConditional = RegExp(/{{CONDITIONAL.*?}}/g);
const findImage = RegExp(/{{IMAGE.*?}}/g);
const findSlice = RegExp(/{{SLICE.*?}}/g);
const findDataDump = RegExp(/{{DATADUMP.*?}}/g);

async function tableifyData(form, tableifiedData, imagePrefix, level = 1) {
  const formKeys = Object.keys(form);
  const mappedNumKeys = [];
  const mappedAlphaKeys = [];
  const startsWithNumber = /^\d+/;
  formKeys.forEach((elem, index) => {
    if (startsWithNumber.test(elem)) {
      mappedNumKeys.push({
        index,
        value: parseFloat(startsWithNumber.exec(elem)[0]),
      });
    } else {
      mappedAlphaKeys.push({ index, value: elem.toLowerCase() });
    }
  });
  mappedNumKeys.sort((a, b) => a.value - b.value);
  mappedAlphaKeys.sort((a, b) => {
    if (a.value > b.value) {
      return 1;
    }
    if (a.value < b.value) {
      return -1;
    }
    return 0;
  });
  const orderedNumKeys = mappedNumKeys.map((elem) => formKeys[elem.index]);
  const orderedAlphaKeys = mappedAlphaKeys.map((elem) => formKeys[elem.index]);
  const orderedArray = orderedNumKeys.concat(orderedAlphaKeys);

  if (level === 1) {
    if (form.time) {
      tableifiedData.push([
        { text: 'Date:', style: 'label' },
        {
          text: moment(form.time).format('Do MMMM YYYY, hh:mm:ss'),
          style: 'data',
        },
      ]);
    }
    if (form.formIdentifier?.formLabel) {
      tableifiedData.push([
        { text: 'Form:', style: 'label' },
        { text: form.formIdentifier.formLabel, style: 'data' },
      ]);
    }
    if (form.user) {
      tableifiedData.push([
        { text: 'User:', style: 'label' },
        { text: form.user, style: 'data' },
      ]);
    }
    if (form.resourceDetails?.name) {
      tableifiedData.push([
        { text: 'Name:', style: 'label' },
        { text: form.resourceDetails.name, style: 'data' },
      ]);
    }
    tableifiedData.push([
      { text: ' ', colSpan: 2, border: [false, false, false, false] },
    ]);

    const regExp = /.+Flag$/m;
    for (let index = orderedArray.length - 1; index >= 0; index--) {
      if (
        orderedArray[index] === '_id' ||
        orderedArray[index] === 'time' ||
        orderedArray[index] === 'user' ||
        regExp.test(orderedArray[index])
      ) {
        orderedArray.splice(index, 1);
      }
    }
  }
  for (const element of orderedArray) {
    // If object containing an image
    if (form[element]['base64data'] !== undefined) {
      const tableRow = [
        {
          image: `data:${form[element].mediatype};base64,${form[element]['base64data']}`,
          colSpan: 2,
        },
      ];
      tableifiedData.push(tableRow);
    }
    // If image then retrieve from S3
    else if (form[element]['mediatype'] !== undefined) {
      const imageS3Name = encodeURIComponent(`${imagePrefix}${element}.base64`);
      const imageBase64data = await getImageByNameFromApi(imageS3Name);
      const tableRow = [
        {
          image: `data:${form[element].mediatype};base64,${imageBase64data}`,
          colSpan: 2,
        },
      ];
      tableifiedData.push(tableRow);
    }
    // If its a non-image object, recurse
    else if (typeof form[element] === 'object') {
      level++;
      await tableifyData(
        form[element],
        tableifiedData,
        `${imagePrefix}-${element}.`,
        level,
      );
      level--;
      if (level === 1) {
        tableifiedData.push([
          { text: ' ', colSpan: 2, border: [false, false, false, false] },
        ]);
      }
    }
    // Else it's data
    else if (element !== '_id') {
      const dataItem = form[element];
      const tableRow = [
        { text: element, style: 'label' },
        { text: dataItem, style: 'data' },
      ];
      tableifiedData.push(tableRow);
      if (level === 1) {
        tableifiedData.push([
          { text: ' ', colSpan: 2, border: [false, false, false, false] },
        ]);
      }
    }
  }
  return tableifiedData;
}

export async function findAndReplace(mapping, form) {
  const keys = Object.keys(mapping);
  for (const section of keys) {
    if (mapping[section] && typeof mapping[section] === 'object') {
      await findAndReplace(mapping[section], form);
    }
    // Replace with Conditional If/then/else-if/then/else-if/then/.../else
    else if (findConditional.test(mapping[section])) {
      // Remove leading/trailing curly braces and "conditional" string
      const logic = mapping[section].slice(14, -2);
      const logicArray = logic.split('␟');
      const comparison = get(form, logicArray.shift());
      // Loop in pairs of 'if/thens'
      for (let x = 0; x <= logicArray.length; x += 2) {
        if (String(comparison) === logicArray[x]) {
          mapping[section] = logicArray[x + 1];
          break;
        }
        if (x + 1 === logicArray.length) {
          // Use the final 'else'
          mapping[section] = logicArray[x];
          break;
        }
        if (x === logicArray.length) {
          // No match
          mapping[section] = '';
        }
      }
    }
    // Replace with Field
    else if (findField.test(mapping[section])) {
      const fieldMatch = mapping[section].match(findField);
      const field = fieldMatch[0].slice(2, -2);
      const fieldArray = field.split('␟');
      mapping[section] = mapping[section].replace(
        findField,
        get(form, fieldArray[1]) ?? '',
      );
    }
    // Replace with Image
    else if (findImage.test(mapping[section])) {
      const image = mapping[section].slice(2, -2);
      const imageArray = image.split('␟');
      const path = imageArray[1];
      const filename = get(form, `${path}.filename`);
      const mediatype = get(form, `${path}.mediatype`);
      const base64data = get(form, `${path}.base64data`);
      const retrievalError = get(form, `${path}.retrievalError`);
      if (base64data) {
        mapping[section] = `data:${mediatype};base64,${base64data}`;
      } else if (retrievalError) {
        mapping[section] = missingOfscImageWarning;
      } else if (filename && mediatype) {
        const imageBase64data = await getImageByNameFromApi(
          encodeURIComponent(
            `${form['formIdentifier']?.formSubmitId}-${form['formIdentifier']?.formLabel}-${path}.base64`,
          ),
        );
        mapping[section] = `data:${mediatype};base64,${imageBase64data}`;
      } else {
        mapping[section] = onePxImage;
      }
    }
    // Replace with a slice of a field
    else if (findSlice.test(mapping[section])) {
      const sliced = mapping[section].slice(2, -2);
      const slicedArray = sliced.split('␟');
      const field = get(form, slicedArray[1]);
      mapping[section] = slicedArray[3]
        ? field.slice(slicedArray[2], slicedArray[3])
        : field.slice(slicedArray[2]);
    }
    // Replace with table containing all data
    else if (findDataDump.test(mapping[section])) {
      const tableifiedData = [];
      const imagePrefix = `${form['formIdentifier']?.formSubmitId}-${form['formIdentifier']?.formLabel}`;
      await tableifyData(form, tableifiedData, imagePrefix);
      mapping[section] = tableifiedData;
    }
  }
  return mapping;
}
