<template>
  <b-field :label="component.fieldLabel">
    <b-input
      v-if="component.formElement === 'textbox'"
      v-model="inputData"
      @input="emitSearchParam()"
    ></b-input>

    <b-select
      v-if="component.formElement === 'select'"
      v-model="inputData"
      @input="emitSearchParam()"
    >
      <option value="">&nbsp;</option>
      <option
        v-for="(option, key) in component.options"
        :key="key"
        :value="option"
      >
        {{ option }}
      </option>
    </b-select>

    <div v-if="component.formElement === 'radio'" class="block">
      <b-radio
        v-for="(option, key) in component.options"
        :key="key"
        v-model="inputData"
        :native-value="Object.values(option)[0]"
        @input="emitSearchParam()"
      >
        {{ Object.keys(option)[0] }}
      </b-radio>
    </div>
  </b-field>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { isEmpty } from 'lodash';

export default defineComponent({
  name: 'SearchComponent',
  props: {
    component: {
      type: Object,
      required: true,
    },
    searchParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputData: undefined,
    };
  },
  computed: {
    fieldName() {
      let path = this.component.fieldName;
      path = this.component.datatype
        ? `${path}_${this.component.datatype}`
        : path;
      path = this.component.comparison
        ? `${path}_${this.component.comparison}`
        : path;
      return path;
    },
    searchField() {
      return {
        fieldName: this.fieldName,
        datatype: this.component.datatype,
        inputData: this.inputData,
      };
    },
  },
  watch: {
    searchParams() {
      if (isEmpty(this.searchParams)) {
        this.inputData = null;
      }
    },
  },
  created() {
    this.inputData = this.component.defaultValue ?? null;
  },
  methods: {
    emitSearchParam() {
      this.$emit('search-param-changed', this.searchField);
    },
  },
});
</script>

<style scoped></style>
