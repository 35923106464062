<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p v-if="exportTemplateName" class="modal-card-title">
        <strong>Template:</strong> {{ exportTemplateName }}
      </p>
      <p v-else class="modal-card-title">Select columns to extract</p>
    </header>
    <section class="modal-card-body">
      <b-table
        :key="exportTemplateIndex"
        :data="exportColumns"
        striped
        draggable
        @dragstart="dragstart"
        @drop="drop"
        @dragover="dragover"
        @dragleave="dragleave"
      >
        <b-table-column field="enabled" label="Enabled">
          <template #header>
            <b-checkbox @input="enableAllExportFields" />
          </template>
          <template #default="props">
            <b-checkbox
              :value="props.row.enabled"
              @input="toggleExport(props.index, props.row.enabled)"
            />
          </template>
        </b-table-column>
        <b-table-column v-slot="props" field="field" label="Field"
          >{{ props.row.field }}
        </b-table-column>
        <b-table-column v-slot="props" label="Position">
          {{ fieldExportPosition(props) }}
        </b-table-column>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <b-button
        v-if="!exportTemplateSaveMode"
        type="is-primary"
        @click="$emit('on-close')"
      >
        Done
      </b-button>
      <b-button
        v-if="!exportTemplateSaveMode"
        @click="exportTemplateSaveMode = true"
      >
        Save as template
      </b-button>
      <b-field v-if="exportTemplateSaveMode" label="Name:" horizontal>
        <b-input v-model="localExportTemplateName"></b-input>
      </b-field>
      <b-button v-if="exportTemplateSaveMode" @click="onAddExportTemplate">
        Save
      </b-button>
      <b-button
        v-if="exportTemplateSaveMode"
        @click="exportTemplateSaveMode = false"
      >
        Cancel
      </b-button>
      <b-button v-if="shouldShowEditButtons" @click="onUpdateExportTemplate">
        Update Template
      </b-button>
      <b-button
        v-if="shouldShowEditButtons"
        type="is-danger"
        @click="onDeleteExportTemplate"
      >
        Delete Template
      </b-button>
    </footer>
  </div>
</template>
<script>
import {
  addExportTemplateToApi,
  deleteExportTemplateFromApi,
  updateExportTemplateInApi,
} from '@/services/forms/searchApi';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ExportFields',
  props: {
    collection: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exportTemplate: {
        exportTemplateName: null,
      },
      exportTemplateSaveMode: false,
      draggingRow: null,
      draggingRowIndex: null,
    };
  },
  computed: {
    ...mapGetters('storeForms', [
      'exportTemplateIndex',
      'exportColumns',
      'exportTemplateName',
      'exportTemplates',
    ]),
    localExportTemplateName: {
      get() {
        return this.exportTemplateName;
      },
      set(value) {
        this.setExportTemplateName(value);
      },
    },
    shouldShowEditButtons() {
      return !this.exportTemplateSaveMode && this.exportTemplateIndex !== -1;
    },
  },
  methods: {
    ...mapActions('storeForms', [
      'setExportTemplateIndex',
      'moveExportColumn',
      'enableExportColumn',
      'setExportTemplateName',
      'addExportTemplate',
      'updateExportTemplate',
      'deleteExportTemplate',
    ]),
    async onAddExportTemplate() {
      try {
        const exportTemplate = {
          name: this.exportTemplateName,
          exportColumns: this.exportColumns,
        };
        await addExportTemplateToApi(this.collection, exportTemplate);
        this.addExportTemplate(exportTemplate);
        this.setExportTemplateIndex(this.exportTemplates.length - 1);
        this.exportTemplateSaveMode = false;
        this.$emit('on-close');
      } catch (err) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Could not save template:<br> ${err}`,
          type: 'is-danger',
        });
      }
    },
    async onUpdateExportTemplate() {
      try {
        const id = this.exportTemplates[this.exportTemplateIndex]._id;
        const exportTemplate = {
          name: this.exportTemplateName,
          exportColumns: this.exportColumns,
        };
        await updateExportTemplateInApi(this.collection, id, exportTemplate);
        this.updateExportTemplate({ id, exportTemplate });
        this.exportTemplateSaveMode = false;
        this.$emit('on-close');
      } catch (err) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Could not update template:<br> ${err}`,
          type: 'is-danger',
        });
      }
    },
    async onDeleteExportTemplate() {
      try {
        const id = this.exportTemplates[this.exportTemplateIndex]._id;
        await deleteExportTemplateFromApi(this.collection, id);
        this.deleteExportTemplate(this.exportTemplateIndex);
        this.exportTemplateSaveMode = false;
        this.setExportTemplateIndex(-1);
        this.$emit('reset-selected-template');
        this.$emit('on-close');
      } catch (err) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Could not delete template:<br> ${err}`,
          type: 'is-danger',
        });
      }
    },
    fieldExportPosition(props) {
      let enabledPosition = 0;
      this.exportColumns.find((column) => {
        if (column.enabled) enabledPosition++;
        if (column.field === props.row.field) {
          if (!column.enabled) enabledPosition = '';
          return true;
        }
      });
      return enabledPosition;
    },
    toggleExport(index, enabled) {
      enabled = !enabled;
      this.enableExportColumn({
        index,
        enabled,
      });
    },
    enableAllExportFields(enable) {
      this.exportColumns.forEach((column) => {
        column.enabled = enable;
      });
    },
    dragstart(payload) {
      this.draggingRow = payload.row;
      this.draggingRowIndex = payload.index;
      payload.event.dataTransfer.effectAllowed = 'copy';
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = 'copy';
      payload?.event?.target?.closest?.('tr').classList.add('is-selected');
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload?.event?.target?.closest?.('tr').classList.remove('is-selected');
      payload.event.preventDefault();
    },
    drop(payload) {
      payload?.event?.target?.closest?.('tr').classList.remove('is-selected');
      const droppedOnRowIndex = payload.index;
      this.moveExportColumn({
        draggingRowIndex: this.draggingRowIndex,
        droppedOnRowIndex,
      });
    },
  },
};
</script>
