import axios from 'axios';
import configData from '@/config/config.json';
import { getIdToken } from '@/services/auth';

const baseURL = configData.pdfify.baseUrl;
const databaseNamePrefix = configData.customer.name;

const apiClient = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

function appendSearchParameters(search, params, prefix) {
  const keys = Object.keys(search);
  keys.forEach((key) => {
    const value = search[key];
    if (value !== null && value !== '') {
      if (typeof value === 'object' && !(value instanceof Date)) {
        appendSearchParameters(search[key], params, key);
      } else if (prefix) {
        params.append(`${prefix}.${key}`, value);
      } else {
        params.append(`${key}`, value);
      }
    }
  });
  return params;
}

export async function getCollectionsFromApi() {
  const url = `forms/${databaseNamePrefix}`;
  const options = {
    method: 'GET',
    url,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function getFormByIdFromApi(collectionName, id) {
  const url = `forms/${databaseNamePrefix}/${encodeURIComponent(
    collectionName,
  )}/${id}`;
  const options = {
    method: 'GET',
    url,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function getImageByNameFromApi(fileName) {
  const url = `image/${fileName}`;
  const options = {
    method: 'GET',
    url,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function searchApi(
  collectionName,
  search,
  pagination,
  sortingPriority,
) {
  const params = new URLSearchParams();
  const url = `forms/${databaseNamePrefix}/${encodeURIComponent(
    collectionName,
  )}`;
  appendSearchParameters(search, params);
  params.append('perPage', pagination.perPage);
  params.append('pageNumber', pagination.pageNumber);
  sortingPriority.forEach((sort, index) => {
    params.append(`${index}-sort-${sort.order}`, sort.field.replace('␝', '.'));
  });
  const options = {
    method: 'GET',
    url,
    params,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function getExportTemplatesFromApi(collectionName) {
  const params = new URLSearchParams();
  const url = `exportTemplates/${databaseNamePrefix}/${encodeURIComponent(
    collectionName,
  )}`;
  const options = {
    method: 'GET',
    url,
    params,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function addExportTemplateToApi(collectionName, template) {
  console.warn('exportTemplateCreate', collectionName, template);
  const url = `exportTemplates/${databaseNamePrefix}/${encodeURIComponent(
    collectionName,
  )}`;
  const options = {
    method: 'POST',
    url,
    data: template,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function updateExportTemplateInApi(collectionName, id, template) {
  console.warn('updateExportTemplateApi', collectionName, id, template);
  const params = new URLSearchParams();
  params.append('_id', id);
  const url = `exportTemplates/${databaseNamePrefix}/${encodeURIComponent(
    collectionName,
  )}/${encodeURIComponent(id)}`;
  const options = {
    method: 'PUT',
    url,
    data: template,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function deleteExportTemplateFromApi(collectionName, id) {
  const url = `exportTemplates/${databaseNamePrefix}/${encodeURIComponent(
    collectionName,
  )}/${encodeURIComponent(id)}`;
  const options = {
    method: 'DELETE',
    url,
  };
  const { data } = await apiClient(options);
  return data;
}

export async function toggleFlagViaApi(collectionName, idArray, value) {
  const url = `forms/${databaseNamePrefix}/${encodeURIComponent(
    collectionName,
  )}`;
  const options = {
    method: 'PATCH',
    url,
    data: {
      id: idArray,
      update: value,
    },
  };
  const { data } = await apiClient(options);
  return data;
}

export async function deleteFormsFromApi(collectionName, idArray) {
  const url = `forms/${databaseNamePrefix}/${encodeURIComponent(
    collectionName,
  )}`;
  const options = {
    method: 'DELETE',
    url,
    data: {
      id: idArray,
    },
  };
  const { data } = await apiClient(options);
  return data;
}
