<template>
  <div class="p-05">
    <p class="is-size-3 has-text-centered">Export</p>
    <p
      class="has-text-primary has-text-centered has-text-weight-semibold"
      style="margin: 1em 0"
    >
      Select export criteria below:
    </p>
    <b-field label="Export template">
      <b-select
        v-model="selectedExportTemplateIndex"
        placeholder="Select a template"
        @input="exportTemplateSelected"
      >
        <option :value="-1">All columns</option>
        <option
          v-for="(template, index) in exportTemplates"
          :key="template._id"
          :value="index"
        >
          {{ template.name }}
        </option>
      </b-select>
    </b-field>

    <b-field label="Fields to extract">
      <b-button type="is-primary" @click="openExportFieldsModal = true">
        Edit Properties
      </b-button>
    </b-field>
    <b-modal
      :active.sync="openExportFieldsModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <export-fields
        v-if="openExportFieldsModal"
        :collection="collection"
        @reset-selected-template="exportTemplateSelected"
        @on-close="onCloseModal"
      />
    </b-modal>

    <div class="buttons">
      <b-button icon-left="download" type="is-info" @click="onExportJson">
        <span>Export to JSON</span>
      </b-button>
      <b-button icon-left="download" type="is-info" @click="exportCsv">
        <span>Export to CSV</span>
      </b-button>
      <b-button type="is-neutral" @click="$emit('on-close')">Close</b-button>
    </div>
  </div>
</template>
<script>
import flatten from 'flat';
import { pick } from 'lodash';
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import { getExportTemplatesFromApi } from '@/services/forms/searchApi';
import ExportFields from '@/components/ofsc/forms/ExportFieldsModal';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ExportSidebar',
  components: { ExportFields },
  props: {
    collection: {
      type: String,
      required: true,
    },
    forms: {
      type: Array,
      required: true,
    },
    checkedRows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      exportableColumns: [],
      openExportFieldsModal: false,
    };
  },
  computed: {
    ...mapGetters('storeForms', [
      'exportTemplates',
      'exportTemplateIndex',
      'exportColumns',
    ]),
    selectedExportTemplateIndex: {
      get() {
        return this.exportTemplateIndex;
      },
      set(value) {
        this.setExportTemplateIndex(value);
      },
    },
  },
  async created() {
    this.setAllExportColumns();
    this.setExportTemplates(await getExportTemplatesFromApi(this.collection));
  },
  methods: {
    ...mapActions('storeForms', [
      'setExportTemplates',
      'setExportTemplateIndex',
      'setExportColumns',
      'unshiftExportColumn',
      'setExportTemplateName',
    ]),
    exportTemplateSelected() {
      if (this.exportTemplateIndex === -1) {
        this.setAllExportColumns();
        this.setExportTemplateName(null);
      } else {
        let fieldsTemplated = true;
        this.setExportColumns(
          this.exportTemplates[this.exportTemplateIndex].exportColumns,
        );
        this.exportableColumns.forEach((formColumn) => {
          if (
            !this.exportColumns.find(
              (templateColumn) => formColumn.field === templateColumn.field,
            )
          ) {
            fieldsTemplated = false;
            this.unshiftExportColumn({
              enabled: false,
              field: formColumn.field,
            });
          }
        });
        if (!fieldsTemplated) {
          this.$buefy.toast.open({
            duration: 10000,
            message: `This export template does not contain all the selected forms' fields. <br>Please check the columns and update the export template.`,
            type: 'is-warning',
          });
        }
        this.setExportTemplateName(
          this.exportTemplates[this.exportTemplateIndex].name,
        );
      }
    },
    flattenedFilteredForms() {
      const formArray = this.checkedRows.map((row) => {
        return this.forms[row.pageNumber - 1][row.index];
      });
      const columns = this.exportColumns
        .filter((column) => column.enabled)
        .map((column) => column.field);
      return formArray.map((form) => {
        const flattenedForm = flatten(form);
        columns.forEach((column) => {
          if (!flattenedForm[column]) {
            flattenedForm[column] = '';
          }
        });
        return pick(flattenedForm, columns);
      });
    },
    onExportJson() {
      const json = JSON.stringify(this.flattenedFilteredForms());
      FileSaver.saveAs(
        new File([json], this.fileName('json'), {
          type: 'application/json;charset=utf-8',
        }),
      );
    },
    exportCsv() {
      const csv = Papa.unparse(this.flattenedFilteredForms());
      FileSaver.saveAs(
        new File([csv], this.fileName('csv'), {
          type: 'text/csv;charset=utf-8',
        }),
      );
    },
    columnKeySorter(a, b) {
      if (a.field < b.field) {
        return -1;
      }
      if (a.field > b.field) {
        return 1;
      }
      return 0;
    },
    setAllExportColumns() {
      const selectedFormsFields = new Map();
      this.checkedRows.forEach((checkedRow) => {
        const flattenedForm = flatten(
          this.forms[checkedRow.pageNumber - 1][checkedRow.index],
        );
        for (const field of Object.keys(flattenedForm)) {
          selectedFormsFields.set(field, null);
        }
      });
      const columnKeys = Array.from(selectedFormsFields.keys(), (field) => ({
        field,
        enabled: true,
      }));
      const exportFields = [];
      columnKeys.sort(this.columnKeySorter).forEach((key) => {
        if (['user', 'time'].includes(key.field)) {
          return exportFields.unshift(key);
        }
        exportFields.push(key);
      });
      this.exportableColumns = exportFields;
      this.setExportColumns(exportFields);
    },
    fileName(extension) {
      return `${this.collection} ${this.$moment().format(
        'YYYY-MM-DD HH꞉mm',
      )}.${extension}`;
    },
    onCloseModal() {
      this.openExportFieldsModal = false;
    },
  },
};
</script>

<style scoped>
.p-05 {
  margin: 0.5rem;
}
</style>
