<template>
  <div class="container is-fluid">
    <b-sidebar
      type="is-white"
      :fullheight="true"
      :fullwidth="false"
      :overlay="true"
      :right="false"
      :can-cancel="['escape']"
      :open.sync="openLeftSidebar"
    >
      <search-sidebar
        v-if="showSearchComponent"
        :search-fields="searchFields"
        @on-close="onClose"
        @on-search="onSearch"
      />
    </b-sidebar>
    <b-sidebar
      type="is-white"
      :fullheight="true"
      :fullwidth="false"
      :overlay="true"
      :right="true"
      :open.sync="openRightSidebar"
      :can-cancel="['escape', 'x']"
    >
      <export-sidebar
        v-if="openRightSidebar"
        :collection="collection"
        :forms="forms"
        :checked-rows="checkedRows"
        :pagination="pagination"
        @on-close="onClose"
      />
    </b-sidebar>

    <div class="columns" style="padding: 1em 0">
      <div class="column">
        <label
          v-if="collection"
          class="label is-medium"
          style="line-height: 2.5em"
        >
          {{ pdfTitle }}
        </label>
        <label v-else style="line-height: 2.5em">Please select a form:</label>
      </div>
      <div class="column">
        <b-select
          v-model="collection"
          class="is-pulled-right"
          @input="selectCollection"
        >
          <option
            v-for="formLabel in collections"
            :key="formLabel"
            :value="formLabel"
          >
            {{ formLabel }}
          </option>
        </b-select>
      </div>
    </div>

    <div style="padding: 1em 0">
      <div class="buttons">
        <b-button
          type="is-info"
          @click="
            openLeftSidebar = true;
            showSearchComponent = true;
          "
        >
          <span class="has-text-weight-semibold">Search</span>
        </b-button>
        <b-button type="is-dark" @click="onClearSearch()">
          <span class="has-text-weight-semibold">Clear Search</span>
        </b-button>
        <b-button type="is-info" @click="getAllData()">
          <span class="has-text-weight-semibold">Fetch Data</span>
        </b-button>
      </div>
    </div>

    <p v-if="error" class="has-text-danger">{{ error }}</p>
    <b-table
      :key="collection"
      :data="tableData"
      :loading="isDataLoading"
      striped
      hoverable
      backend-pagination
      :paginated="paginated"
      :current-page="pagination.pageNumber"
      :per-page="pagination.perPage"
      :total="pagination.total"
      sort-multiple
      :sort-multiple-data="sortingPriority"
      backend-sorting
      @sort="sortPressed"
      @sorting-priority-removed="sortingPriorityRemoved"
      @page-change="onPageChange"
    >
      <b-table-column>
        <template #header>
          <b-checkbox
            v-model="checkAll[pagination.pageNumber - 1]"
            @input="selectAllRows"
          />
        </template>
        <template #default="props">
          <b-checkbox
            v-model="props.row.selected"
            @input="selectRow(props.row, props.row.selected)"
          />
        </template>
      </b-table-column>
      <b-table-column
        v-for="column in tableColumns"
        :key="column.field"
        v-slot="props"
        :field="column.field"
        :label="column.label"
        :width="column.width"
        :numeric="column.numeric"
        :visible="column.visible"
        :centered="column.displayElement === 'tickCross'"
        sortable
      >
        <span v-if="column.displayElement === 'tickCross'">
          <b-icon
            v-if="props.row[column.field] === true"
            icon="check"
            size="is-medium"
            type="is-success"
          />
          <b-icon v-else icon="times" size="is-medium" type="is-light" />
        </span>
        <span v-else-if="column.field === 'time'">
          {{ $moment(props.row[column.field]).format('DD/MM/YY HH:mm') }}
        </span>
        <span v-else>
          {{ props.row[column.field] }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" label="View">
        <b-button
          type="is-info"
          size="is-small"
          @click="onOpenPDF(props.row.index)"
        >
          View
        </b-button>
      </b-table-column>
      <b-table-column v-slot="props" label="Download">
        <b-button
          type="is-primary"
          size="is-small"
          @click="onOpenPDF(props.row.index, 'download')"
        >
          Download
        </b-button>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="sad-tear" size="is-large"> </b-icon>
            </p>
            <p>No results.</p>
          </div>
        </section>
      </template>
      <template slot="footer">
        <div class="columns">
          <div class="column is-four-fifths buttons">
            <b-button
              type="is-primary"
              size="is-small"
              :disabled="checkedRows.length === 0"
              @click="downloadZip()"
            >
              Download zip
            </b-button>
            <b-button
              type="is-primary"
              size="is-small"
              :disabled="checkedRows.length === 0"
              @click="openRightSidebar = true"
            >
              Export data
            </b-button>
            <span
              v-for="(flag, index) in flags"
              :key="index"
              class="buttons has-addons"
              style="display: inline-flex; margin-right: 0.5rem"
            >
              <b-button
                type="is-success"
                size="is-small"
                :disabled="checkedRows.length === 0"
                @click="onToggle(flag.field, true)"
              >
                {{ flag.affirmativeLabel }}
              </b-button>
              <b-button
                type="is-light"
                size="is-small"
                :disabled="checkedRows.length === 0"
                @click="onToggle(flag.field, false)"
              >
                {{ flag.negativeLabel }}
              </b-button>
            </span>
            <b-modal
              :active.sync="deleteModalVisible"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-modal
            >
              <div class="modal-card" style="width: auto">
                <header class="modal-card-head">Delete forms</header>
                <section class="modal-card-body">
                  <p>
                    Are you sure you want to delete the
                    {{
                      checkedRows.length === 1
                        ? 'selected form'
                        : `${checkedRows.length} selected forms`
                    }}?
                  </p>
                </section>
                <footer class="modal-card-foot">
                  <b-button type="is-danger" @click="onDeleteForms">
                    Delete
                  </b-button>
                  <b-button @click="deleteModalVisible = false">
                    Cancel
                  </b-button>
                </footer>
              </div>
            </b-modal>
            <b-button
              type="is-danger"
              size="is-small"
              :disabled="checkedRows.length === 0"
              style="margin-right: 4em"
              @click="deleteModalVisible = true"
            >
              Delete
            </b-button>
            <div v-if="checkedRows.length !== 0" style="display: inline-block">
              <span class="tag is-dark">{{ checkedRows.length }}</span> rows
              selected
            </div>
          </div>
          <div class="column">
            <b-select
              v-model="pagination.perPage"
              size="is-small"
              class="is-pulled-right"
              @input="onPerPageChange"
            >
              <option value="5">5 per page</option>
              <option value="10">10 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
            </b-select>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { cloneDeep, get } from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';

import { findAndReplace } from '@/services/forms/mapping';
import {
  deleteFormsFromApi,
  getCollectionsFromApi,
  getFormByIdFromApi,
  searchApi,
  toggleFlagViaApi,
} from '@/services/forms/searchApi';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import SearchSidebar from '@/components/ofsc/forms/SearchSidebar';
import { mapActions, mapGetters } from 'vuex';
import ExportSidebar from '@/components/ofsc/forms/ExportSidebar';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  MyFont: {
    normal: 'DejaVuSans.ttf',
    bold: 'DejaVuSans.ttf',
    italics: 'DejaVuSans.ttf',
    bolditalics: 'DejaVuSans.ttf',
  },
};

const initTableColumns = [
  {
    field: '_id',
    visible: false,
  },
  {
    field: 'index',
    numeric: true,
    visible: false,
  },
  {
    field: 'formIdentifier␝formSubmitId',
    label: 'ID',
    width: '40',
    numeric: true,
  },
  {
    field: 'time',
    label: 'Date',
  },
];

export default defineComponent({
  name: 'FormsDisplay',
  components: {
    ExportSidebar,
    SearchSidebar,
  },
  data() {
    return {
      mappingComplete: false,
      collection: null,
      collections: [],
      forms: [],
      mapping: null,
      tableData: [],
      checkedRows: [],
      checkAll: [],
      isDataLoading: false,
      pdfTitle: null,
      tableColumns: initTableColumns,
      pagination: {
        perPage: 10,
        pageNumber: 1,
        total: null,
      },
      sortingPriority: [{ field: 'time', order: 'desc' }],
      pdfNomenclature: [
        {
          field: 'formIdentifier.formLabel',
        },
        {
          string: '_',
        },
        {
          field: 'time',
          formatDate: 'YYYY-MM-DD_HH꞉mm',
        },
      ],
      month: null,
      dateRange: null,
      formRiskRating: null,
      searchFields: [],
      flags: [],
      showSearchComponent: false,
      openLeftSidebar: false,
      openRightSidebar: false,
      deleteModalVisible: false,
      error: undefined,
    };
  },
  computed: {
    ...mapGetters('storeForms', ['searchParams']),
    paginated() {
      return this.pagination.total > this.pagination.perPage;
    },
    defaultSearchParams() {
      const defaultSearchParams = {};
      this.mapping.searchFields.forEach((field) => {
        if (field.defaultValue !== undefined) {
          let path = field.fieldName;
          path = field.datatype ? `${path}_${field.datatype}` : path;
          path = field.comparison ? `${path}_${field.comparison}` : path;
          defaultSearchParams[path] = field.defaultValue;
        }
      });
      return defaultSearchParams;
    },
  },
  async created() {
    await this.getAllData();
  },
  methods: {
    ...mapActions('storeForms', ['setSearchParams']),
    selectAllRows() {
      if (this.checkAll[this.pagination.pageNumber - 1]) {
        this.tableData.forEach((tableRow) => {
          const checkedRowIndex = this.checkedRows.findIndex(
            (checkedRow) =>
              checkedRow.index === tableRow.index &&
              checkedRow.pageNumber === this.pagination.pageNumber,
          );
          if (checkedRowIndex === -1) {
            this.tableData[tableRow.index].selected = true;
            this.checkedRows.push(tableRow);
          }
        });
      } else {
        this.tableData.forEach((tableRow) => {
          const checkedRowIndex = this.checkedRows.findIndex(
            (checkedRow) =>
              checkedRow.index === tableRow.index &&
              checkedRow.pageNumber === this.pagination.pageNumber,
          );
          if (checkedRowIndex !== -1) {
            this.tableData[tableRow.index].selected = false;
            this.checkedRows.splice(checkedRowIndex, 1);
          }
        });
      }
    },
    selectRow(obj, ticked) {
      const checkedRowIndex = this.checkedRows.findIndex(
        (row) =>
          row.index === obj.index &&
          row.pageNumber === this.pagination.pageNumber,
      );
      if (checkedRowIndex >= 0 && !ticked) {
        this.checkedRows.splice(checkedRowIndex, 1);
      } else if (ticked) {
        this.checkedRows.push(obj);
      }
    },
    async getAllData() {
      this.checkedRows = [];
      this.checkAll = [];
      await this.getCollectionsFromApi();
      if (!this.collection) this.collection = this.collections[0];
      await this.selectCollection();
    },
    async selectCollection() {
      // FIXME ugly way of coding... must be better way to check if a require file exists?
      // TODO Lets store the mappers in the DB rather than files, with template (dated) versions so the user can alter a form's structure without ill effect
      try {
        this.forms = [];
        this.checkAll = [];
        this.pagination.pageNumber = 1;
        this.checkedRows = [];
        this.checkAll = [];
        this.mappingComplete = false;
        this.sortingPriority = [{ field: 'time', order: 'desc' }];
        this.tableColumns = [];
        this.tableColumns.push(...initTableColumns);
        this.mapping = require(`@/../db/${this.collection}.json`);
        this.searchFields = this.mapping.searchFields
          ? this.mapping.searchFields
          : [];
        this.showSearchComponent = false;
        this.setSearchParams(this.defaultSearchParams);
        this.pdfTitle = this.mapping.pdfTitle || null;
        this.mapping.tableColumns?.forEach((column) => {
          column.field = column.field.replace('.', '␝');
          this.tableColumns.push(column);
        });
        this.pdfNomenclature = this.mapping.pdfNomenclature
          ? this.mapping.pdfNomenclature
          : [
              { field: 'formIdentifier.formLabel' },
              { string: '_' },
              { field: 'time', formatDate: 'YYYY-MM-DD_HH꞉mm' },
            ];
      } catch (e) {
        this.mapping = require(`@/../db/default.json`);
        this.mapping.tableColumns.forEach((column) => {
          column.field = column.field.replace('.', '␝');
          this.tableColumns.push(column);
        });

        this.searchFields = this.mapping.searchFields
          ? this.mapping.searchFields
          : [];
        this.showSearchComponent = false;
        this.setSearchParams(this.defaultSearchParams);
        this.pdfTitle = null;

        this.mapping.pdfNomenclature = [
          { field: 'formIdentifier.formLabel' },
          { string: '_' },
          { field: 'time', formatDate: 'YYYY-MM-DD_HH꞉mm' },
        ];
      } finally {
        this.mappingComplete = true;
      }

      try {
        await this.getDataFromApi();
      } catch (err) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Could not access database.<br> ${err}`,
          type: 'is-danger',
        });
      }
    },
    onSearch() {
      this.forms = [];
      this.checkedRows = [];
      this.checkAll = [];
      this.pagination.pageNumber = 1;
      this.openLeftSidebar = false;
      this.getDataFromApi();
    },
    onClearSearch() {
      this.checkedRows = [];
      this.checkAll = [];
      this.showSearchComponent = false;
      this.setSearchParams(this.defaultSearchParams);
      this.getDataFromApi();
    },
    onPageChange(pageNumber) {
      this.pagination.pageNumber = pageNumber;
      if (this.forms[pageNumber - 1]) {
        this.mapTableData();
      } else {
        this.getDataFromApi();
      }
    },
    onPerPageChange() {
      this.checkedRows = [];
      this.checkAll = [];
      this.pagination.pageNumber = 1;
      this.getDataFromApi();
    },
    sortingPriorityRemoved(field) {
      this.forms = [];
      this.checkedRows = [];
      this.checkAll = [];
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== field,
      );
      this.getDataFromApi();
    },
    sortPressed(field, order) {
      this.forms = [];
      this.checkedRows = [];
      this.checkAll = [];
      let existingPriority = this.sortingPriority.filter(
        (i) => i.field === field,
      )[0];
      if (existingPriority) {
        existingPriority.order =
          existingPriority.order === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortingPriority.push({ field, order });
      }
      this.getDataFromApi();
    },
    onClose() {
      this.openLeftSidebar = false;
      this.openRightSidebar = false;
    },
    async onOpenPDF(index, action = 'open') {
      const id = this.forms[this.pagination.pageNumber - 1][index]._id;
      try {
        const form = await getFormByIdFromApi(this.collection, id);
        const mapping = cloneDeep(this.mapping);
        try {
          const docDefinition = await findAndReplace(mapping, form);
          if (action === 'open') {
            pdfMake.createPdf(docDefinition).open();
          } else {
            const pdfName = this.pdfName(form, index);
            pdfMake.createPdf(docDefinition).download(pdfName);
          }
        } catch (err) {
          this.$buefy.toast.open({
            duration: 10000,
            message: `Could not open PDF.<br> Form does not fit the template`,
            type: 'is-danger',
          });
        }
      } catch (err) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Lost connection to the database`,
          type: 'is-danger',
        });
      }
    },
    pdfName(form, id) {
      let pdfName = '';
      this.pdfNomenclature.forEach((nomenclatureSegment) => {
        if (nomenclatureSegment['string'] !== undefined) {
          pdfName += nomenclatureSegment.string;
        } else {
          let nameFromField = get(form, nomenclatureSegment.field);
          if (nomenclatureSegment.hasOwnProperty['formatDate'] !== undefined) {
            nameFromField = this.$moment(nameFromField).format(
              nomenclatureSegment.formatDate,
            );
          }
          pdfName += nameFromField;
        }
      });
      return `${pdfName}-${id}.pdf`;
    },
    async downloadZip() {
      try {
        this.isDataLoading = true;
        const pdfArrayNames = this.checkedRows.map((row) => {
          return this.pdfName(
            this.forms[row.pageNumber - 1][row.index],
            row.index,
          );
        });
        const pdfArray = [];
        for (const row of this.checkedRows) {
          const pdf = await this.generatePDF(row.pageNumber, row.index);
          pdfArray.push(pdf);
        }
        await this.zipFiles(pdfArrayNames, pdfArray);
      } catch (err) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Could not create ZIP.<br> ${err}`,
          type: 'is-danger',
        });
      } finally {
        this.isDataLoading = false;
      }
    },
    generatePDF(pageNumber, index) {
      return new Promise((resolve, reject) => {
        const id = this.forms[pageNumber - 1][index]._id;
        getFormByIdFromApi(this.collection, id)
          .then(async (form) => {
            const mapping = cloneDeep(this.mapping);
            const docDefinition = await findAndReplace(mapping, form);
            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getBase64((data) => {
              resolve(data);
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async zipFiles(pdfArrayNames, pdfArray) {
      const zip = new JSZip();
      let i = 0;
      pdfArray.forEach((pdf) => {
        zip.file(pdfArrayNames[i], pdf, { base64: true });
        i++;
      });

      const content = await zip.generateAsync({ type: 'blob' });
      FileSaver.saveAs(
        new File([content], this.fileName('zip'), {
          type: 'application/zip',
        }),
      );
    },
    async getCollectionsFromApi() {
      try {
        this.isDataLoading = true;
        const collections = await getCollectionsFromApi();
        this.collections = collections.sort();
      } catch (err) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Could fetch collections.<br> ${err}`,
          type: 'is-danger',
        });
      } finally {
        this.isDataLoading = false;
      }
    },
    mapTableData() {
      this.tableData = this.forms[this.pagination.pageNumber - 1].map(
        (form, index) => {
          const obj = {};
          obj.index = index;
          obj['formIdentifier␝formSubmitId'] = form.formIdentifier.formSubmitId;
          obj.time = this.$moment(form.time).toDate();
          if (this.mapping?.tableColumns) {
            this.mapping.tableColumns.forEach((column) => {
              obj[`${column.field}`] = get(
                form,
                column.field.replace('␝', '.'),
              );
            });
          }
          obj.selected = !!this.checkedRows.find((row) => {
            return (
              row.index === obj.index &&
              row.pageNumber === this.pagination.pageNumber
            );
          });
          obj.pageNumber = this.pagination.pageNumber;
          return obj;
        },
      );
    },
    async getDataFromApi() {
      try {
        this.isDataLoading = true;
        const response = await searchApi(
          this.collection,
          this.searchParams,
          this.pagination,
          this.sortingPriority,
        );
        this.forms[this.pagination.pageNumber - 1] = response.forms;
        if (this.pagination.pageNumber === 1) {
          this.pagination.total = response.total;
        }
        this.mapTableData();
        this.flags = this.mapping?.flags ? this.mapping.flags : [];
      } catch (err) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Could not access data for the table:<br> ${err}`,
          type: 'is-danger',
        });
      } finally {
        this.isDataLoading = false;
      }
    },
    async onToggle(flagField, boolValue) {
      try {
        const idArray = this.checkedRows.map((row) => {
          return this.forms[row.pageNumber - 1][row.index]._id;
        });
        const update = {};
        update[flagField] = boolValue;
        await toggleFlagViaApi(this.collection, idArray, update);
        this.checkedRows.forEach((row) => {
          this.forms[row.pageNumber - 1][row.index][flagField] = boolValue;
          if (row.pageNumber === this.pagination.pageNumber) {
            this.tableData[row.index][flagField] = boolValue;
          }
        });
      } catch (err) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Could not toggle flag.<br> ${err}`,
          type: 'is-danger',
        });
      }
    },
    async onDeleteForms() {
      try {
        const idArray = this.checkedRows.map((row) => {
          return this.forms[row.pageNumber - 1][row.index]._id;
        });
        await deleteFormsFromApi(this.collection, idArray);
        this.checkedRows.forEach((checkedRow) => {
          const tableDataIndex = this.tableData.findIndex(
            (tableRow) =>
              tableRow.index === checkedRow.index &&
              tableRow.pageNumber === checkedRow.pageNumber,
          );
          if (tableDataIndex > -1) {
            this.tableData.splice(tableDataIndex, 1);
          }
          this.forms[checkedRow.pageNumber - 1].splice(checkedRow.index, 1);
        });
        this.checkedRows = [];
        this.checkAll = [];
        this.deleteModalVisible = false;
      } catch (err) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Could not delete form.<br> ${err}`,
          type: 'is-danger',
        });
      }
    },
    fileName(extension) {
      return `${this.collection} ${this.$moment().format(
        'YYYY-MM-DD HH꞉mm',
      )}.${extension}`;
    },
  },
});
</script>
